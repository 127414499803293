import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EvaluantionEmoji from './EvaluationEmoji';
import style from './evaluationMessages.module.scss';
import Text from '../../../../../../atoms/Text';
import { useTalk } from '../../../../../../../contexts/talk';
import config from '../../../../../../../api/config';
import { QuestionRound } from '../../../../../../../contexts/talk/types';
import client from '../../../../../../../api';

function EvaluationMessage() {
  const { appendRound } = useTalk();

  const [hover, setHover] = useState<number>();
  const [clicked, setClicked] = useState<boolean>(false);

  const handleClick = async (item: string | number | boolean) => {
    const key = localStorage.getItem(config.localStorage.xKey);

    setClicked(true);

    try {
      const { data } = await client.patch<QuestionRound>(`/chats/${key}`, { data: item });

      appendRound({ question: data });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const emojis = [
    {
      value: 0,
      text: <>😡</>,
    },
    {
      value: 1,
      text: <>🙁</>,
    },
    {
      value: 2,
      text: <>😐</>,
    },
    {
      value: 3,
      text: <>🙂</>,
    },
    {
      value: 4,
      text: <>😍</>,
    },
  ];

  return (
    <div className={style.evaluationContainer} onMouseLeave={() => !clicked && setHover(-1)}>
      <div className={style.titleBox}>
        <Text color='#444' fontSize='14px' bold>
          Avalie a nossa conversa
        </Text>
      </div>
      <div className={style.contentEmoji}>
        {emojis.map(item => (
          <EvaluantionEmoji
            id={item.value}
            emoji={item.text}
            key={item.value}
            handleClick={handleClick}
            hover={hover}
            setHover={!clicked ? setHover : () => null}
          />
        ))}
      </div>
    </div>
  );
}

EvaluationMessage.defaultProps = {
  action: null,
};

EvaluationMessage.propTypes = {
  action: PropTypes.func,
};

export default EvaluationMessage;
