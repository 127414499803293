import { FC } from 'react';
import { QuestionResponseFlowEnum } from '../../../../../../contexts/talk/types';
import Presentation from '../../../../../organisms/Presentation';
import Skills from '../../../../../organisms/Skills';
import TextQuestion from './Fields/TextQuestion';

interface TProps {
  type?: QuestionResponseFlowEnum;
  label: string;
}

export const Question: FC<TProps> = ({ type, label }) => {
  switch (type) {
    case QuestionResponseFlowEnum.PRESENTATION:
      return <Presentation />;

    case QuestionResponseFlowEnum.SKILLS:
      return <Skills />;

    default:
      return <TextQuestion value={label} />;
  }
};
