import { FC } from 'react';
import styled from 'styled-components';
import { KeyValue, QuestionResponseTypeEnum } from '../../../../../../contexts/talk/types';

type TTextFieldProps = {
  handleAction?: (value: string | number | boolean, answer?: string) => void;
  returnType?: QuestionResponseTypeEnum;
  data: Array<KeyValue>;
};

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  width: 100%;
  height: 53px;
  border-radius: 16px;
  border: 1px solid #767676;
  font-family: 'Inter, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #767676;

  &:last-child {
    background: #767676;
    color: #fff;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DecisionField: FC<TTextFieldProps> = props => {
  const { handleAction, data } = props;

  return (
    <Flex>
      {data.map(item => (
        <Button
          key={String(item.value)}
          onClick={() => handleAction?.(item.key, String(item.value))}
        >
          {String(item.value)}
        </Button>
      ))}
    </Flex>
  );
};

export default DecisionField;
