import { FC, memo } from 'react';
import styled from 'styled-components';
import { useDialog } from '../../../contexts/dialog';
import { SizesEnum } from '../../../contexts/dialog/types';

import { FloatingActionButtonProps } from './types';

const StyledButton = styled.button`
  width: 58px;
  height: 58px;
  background-color: ${({ theme }) => theme.primaryColor};
  border-radius: 29px 6px 29px 29px;
  position: fixed;
  border: 0;
  margin: 0;
  padding: 0;
  right: 25px;
  bottom: 25px;
  cursor: pointer;
`;

const FloatingActionButton: FC<FloatingActionButtonProps> = () => {
  const { handleDialog, dialogState } = useDialog();

  return (
    <StyledButton
      type='button'
      onClick={() =>
        handleDialog({
          size: SizesEnum.MD,
          opened: !dialogState.opened,
        })
      }
    >
      <svg
        width='31'
        height='30'
        viewBox='0 0 31 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22.0863 14.0337C22.041 12.9963 21.7496 11.9672 21.2101 11.0369L16.4519 2.83083C16.089 2.2049 15.8384 1.53445 15.6991 0.84668L15.5005 1.18966C14.3225 3.22074 14.3225 5.72309 15.5003 7.75417L19.3068 14.3191C20.4846 16.3504 20.4846 18.8529 19.3068 20.884L19.108 21.227C18.9689 20.5392 18.7181 19.8687 18.3552 19.243L12.6453 9.39554C12.2827 8.76981 12.0319 8.09916 11.8927 7.41159L11.6939 7.75457C10.5202 9.7788 10.5165 12.2711 11.6822 14.2985L11.6819 14.2987L15.5005 20.884C16.678 22.9151 16.678 25.4176 15.5005 27.4489L15.3014 27.7919C15.1623 27.1041 14.9117 26.4337 14.5488 25.8077L9.79059 17.6016C9.15246 16.501 8.86079 15.262 8.91419 14.0347C3.82062 15.3597 0.304688 18.1734 0.304688 21.4312C0.304688 25.9632 7.10819 29.6371 15.5005 29.6371C23.8925 29.6371 30.6958 25.9632 30.6958 21.4312C30.6958 18.1732 27.1803 15.3589 22.0863 14.0337Z'
          fill='white'
        />
      </svg>
    </StyledButton>
  );
};

export default memo(FloatingActionButton);
