export type OnClose = (payload?: Record<string, unknown>) => void;

export enum QuestionResponseTypeEnum {
  INT = 0,
  FLOAT = 1,
  STRING = 2,
  BOOLEAN = 3,
}

export enum QuestionResponseMaskEnum {
  CPF = 0,
  RG = 1,
  EMAIL = 2,
  PHONE = 7,
  CEP = 4,
  RATING = 5,
  DECISION = 6,
}

export enum QuestionResponseFlowEnum {
  RENEW = 'RENOVAÇÃO',
  QOTE = 'COTAÇÃO DE SEGURO AUTO',
  QUESTIONS = 'DÚVIDAS',
  PRESENTATION = 'APRESENTAÇÃO',
  SKILLS = 'SKILLS',
}

export enum InterceptorTypesEnum {
  OPTIONS = 0,
  PRODUCT = 1,
}

export interface WMModelDTO {
  id: string;
  name: string;
  slugged: string;
  imagePath: string;
}

export interface KeyValue {
  key: string | number | boolean;
  value: string | number | boolean;
}

export enum QuestionComponentEnum {
  PROPOSALS = 1,
  SELECT_BUTTON = 2,
  RATING = 3,
  SELECT_BUTTON_WITH_SEARCH = 4,
}

export enum QuestionOptionsTypeEnum {
  ARRAY_STRING = 0,
  ARRAY_NUMBER = 1,
  ARRAY_KEY_VALUE = 2,
  ARRAY_WM_BRAND_DTO = 10,
  ARRAY_WM_MODEL_DTO = 11,
  ARRAY_WM_VERSION_DTO = 12,
  WM_VERSION_DTO = 13,
  STORE = 14,
}

export interface QuestionRound {
  question: {
    labels: Array<string>;
    options: {
      data: Array<KeyValue> | WMModelDTO;
      component?: QuestionComponentEnum;
      type?: QuestionOptionsTypeEnum;
    };
  };
  responseType: {
    isCollection?: boolean;
    mask: QuestionResponseMaskEnum;
    max?: number;
    min?: number;
    type: number;
  } | null;
  flow?: QuestionResponseFlowEnum;
  editable: null | {
    field: string;
  };
}

export interface AnswerRound {
  id?: number;
  value: string | number;
  editable?: boolean;
}

export interface Round {
  question?: QuestionRound;
  answer?: AnswerRound;
}

export interface State {
  rounds: Array<Round>;
  search?: string;
}

export type HandleTalkOptions = State;

export type TalkContextPayload = {
  handleTalk: (options?: HandleTalkOptions) => void;
  appendRound: (round: Round) => void;
  setSearch: (search: string) => void;
  state: State;
};
