import { useState } from 'react';
import styled from 'styled-components';
import client from '../../../../../api';
import config from '../../../../../api/config';
import { useTalk } from '../../../../../contexts/talk';
import { QuestionResponseMaskEnum, Round } from '../../../../../contexts/talk/types';
import FooterActions from './Actions';

const S = {
  Main: styled.div<{ removeBG: boolean }>`
    background: ${({ removeBG }) => (removeBG ? '#fff' : '#e6e8ec')};
    padding: 32px 24px;
  `,
};

function Footer() {
  const {
    state: { rounds },
    appendRound,
  } = useTalk();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  const handleAction = async (value: string | number | boolean, answer?: string) => {
    setLoading(true);

    answer &&
      appendRound({
        answer: {
          id: Math.random(),
          editable: false,
          value: answer,
        },
      });

    const xKey = localStorage.getItem(config.localStorage.xKey);

    try {
      const { data } = await client.patch(`/chats/${xKey}`, {
        data: value,
      });

      appendRound({ question: data });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const round = rounds[rounds.length - 1] as Round;

  const { question } = round;

  return (
    <S.Main removeBG={question?.responseType?.mask === QuestionResponseMaskEnum.DECISION}>
      <FooterActions
        action={handleAction}
        mask={question?.responseType?.mask}
        type={question?.responseType?.type}
        data={question?.question?.options?.data}
        component={question?.question?.options?.component}
      />
    </S.Main>
  );
}

export default Footer;
