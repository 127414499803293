import styled from 'styled-components';
import { useDialog } from '../../../../../contexts/dialog';
import Text from '../../../../atoms/Text';

const S = {
  Main: styled.div`
    height: 119px;
    background-color: #cc0000;
  `,
  Header: styled.div`
    .container {
      padding: 32px 24px 0;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .info {
      height: 40px;

      .icon {
        width: 36px;
        height: 36px;
        background-color: #fff;
        border-radius: 18px;
        justify-content: center;
        margin-right: 16px;
      }
    }

    .onlinetag {
      justify-content: start;
      margin-top: 4px;

      span {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: #27ae60;
        margin-right: 4px;
      }
    }

    .close {
      width: 40px;
      height: 40px;
      border: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      background: transparent;
    }
  `,
  Loading: styled.div``,
};

function Header() {
  const { handleDialog } = useDialog();

  return (
    <S.Main>
      <S.Loading />

      <S.Header>
        <div className='container'>
          <div className='flex'>
            <div className='info flex'>
              <div className='icon flex'>
                <svg
                  width='20'
                  height='18'
                  viewBox='0 0 20 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.088 8.2495C14.0599 7.6056 13.879 6.96683 13.5442 6.38943L10.5908 1.296C10.3655 0.907484 10.21 0.491344 10.1235 0.0644531L10.0002 0.277336C9.26906 1.53801 9.26906 3.09119 10.0001 4.35186L12.3628 8.42663C13.0938 9.68743 13.0938 11.2407 12.3628 12.5014L12.2394 12.7143C12.153 12.2874 11.9973 11.8713 11.7721 11.4829L8.22807 5.37064C8.00297 4.98226 7.8473 4.56599 7.76092 4.13923L7.63752 4.35211C6.90899 5.60853 6.90673 7.15546 7.63023 8.41388L7.63011 8.414L10.0002 12.5014C10.7311 13.7621 10.7311 15.3154 10.0002 16.5762L9.87669 16.7891C9.79031 16.3622 9.63477 15.946 9.40954 15.5575L6.45616 10.4641C6.06008 9.78093 5.87905 9.0119 5.91219 8.25012C2.75066 9.07253 0.568359 10.819 0.568359 12.841C0.568359 15.654 4.79122 17.9343 10.0002 17.9343C15.2091 17.9343 19.4318 15.654 19.4318 12.841C19.4318 10.8188 17.2498 9.07203 14.088 8.2495Z'
                    fill='#CC0000'
                  />
                </svg>
              </div>
              <div>
                <Text color='white' fontSize='14px' lineHeight='17.5px' bold>
                  Santander Auto
                </Text>

                <div className='onlinetag flex'>
                  <span />
                  <Text color='white' fontSize='10px'>
                    Online
                  </Text>
                </div>
              </div>
            </div>

            <button type='button' onClick={() => handleDialog({ opened: false })} className='close'>
              <svg
                width='26'
                height='24'
                viewBox='0 0 26 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M15.0725 11.9508L22.0794 5.26974C22.5523 4.81881 22.5523 4.08992 22.0794 3.63898C21.6065 3.18805 20.842 3.18805 20.3691 3.63898L13.3622 10.32L6.35528 3.63898C5.88235 3.18805 5.11792 3.18805 4.64498 3.63898C4.17205 4.08992 4.17205 4.81881 4.64498 5.26974L11.6519 11.9508L4.64498 18.6318C4.17205 19.0828 4.17205 19.8116 4.64498 20.2626C4.88084 20.4875 5.19049 20.6005 5.50013 20.6005C5.80978 20.6005 6.11942 20.4875 6.35528 20.2626L13.3622 13.5815L20.3691 20.2626C20.605 20.4875 20.9146 20.6005 21.2242 20.6005C21.5339 20.6005 21.8435 20.4875 22.0794 20.2626C22.5523 19.8116 22.5523 19.0828 22.0794 18.6318L15.0725 11.9508Z'
                  fill='white'
                />
              </svg>
            </button>
          </div>
        </div>
      </S.Header>
    </S.Main>
  );
}

export default Header;
