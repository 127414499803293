import { FC } from 'react';
import {
  KeyValue,
  QuestionComponentEnum,
  QuestionResponseMaskEnum,
  QuestionResponseTypeEnum,
  WMModelDTO,
} from '../../../../../../contexts/talk/types';
import DecisionField from '../Fields/DecisionField';
import TextField from '../Fields/TextField';
import TextFieldWithSearch from '../Fields/TextFieldWithSearch';

type TFooterActionsProps = {
  type?: QuestionResponseTypeEnum;
  mask?: QuestionResponseMaskEnum;
  action?: (value: string | number | boolean, answer?: string) => void;
  data?: Array<KeyValue> | WMModelDTO;
  component?: QuestionComponentEnum;
};

const FooterActions: FC<TFooterActionsProps> = props => {
  const { mask, action, type, data, component } = props;

  switch (mask) {
    case QuestionResponseMaskEnum.CPF:
      return (
        <TextField
          mask='cpf'
          placeholder='Digite seu CPF'
          returnType={type}
          handleAction={action}
        />
      );

    case QuestionResponseMaskEnum.RG:
      return (
        <TextField
          mask='date'
          placeholder='Digite seu RG'
          returnType={type}
          handleAction={action}
        />
      );

    case QuestionResponseMaskEnum.EMAIL:
      return (
        <TextField
          mask='email'
          placeholder='Digite seu E-mail'
          returnType={type}
          handleAction={action}
        />
      );

    case QuestionResponseMaskEnum.PHONE:
      return (
        <TextField
          mask='phone'
          placeholder='Digite seu Telefone'
          returnType={type}
          handleAction={action}
        />
      );

    case QuestionResponseMaskEnum.CEP:
      return (
        <TextField
          mask='zipcode'
          placeholder='Digite seu CEP'
          returnType={type}
          handleAction={action}
        />
      );

    case QuestionResponseMaskEnum.DECISION:
      return (
        <DecisionField returnType={type} handleAction={action} data={data as Array<KeyValue>} />
      );

    default:
      switch (component) {
        case QuestionComponentEnum.SELECT_BUTTON_WITH_SEARCH:
          return (
            <TextFieldWithSearch
              placeholder='Escreva uma resposta'
              returnType={type}
              handleAction={action}
            />
          );

        default:
          return (
            <TextField placeholder='Escreva uma resposta' returnType={type} handleAction={action} />
          );
      }
  }
};

export default FooterActions;
