import { FC, useRef } from 'react';
import styled from 'styled-components';
import { useTalk } from '../../../../../contexts/talk';
import { QuestionComponentEnum } from '../../../../../contexts/talk/types';
import LoadingQuestion from '../../../../atoms/LoadingQuestion';
import Interceptor from './Interceptor';

import { Question } from './Question';
import { Response } from './Response';

const S = {
  Main: styled.div<{ disableFooter: boolean }>`
    position: relative;
    background: #fff;
    padding: 31px 0 0;
    margin-top: -31px;
    border-radius: 32px 32px 0 0;
    height: calc(100% - 130px - ${({ disableFooter }) => (disableFooter ? '-12px' : '98px')});
    overflow: hidden;
    transition: all ease 0.3s;

    &::before {
      content: '';
      width: 100%;
      height: 40px;
      display: block;
      opacity: 0.95;
      position: absolute;
      top: 31px;
      background-image: linear-gradient(#fff, transparent);
      z-index: 2;
    }
  `,
  ScrollView: styled.div`
    overflow-y: scroll;
    padding: 0 24px;
    height: 100%;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track-piece,
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    .round:not(:last-child) {
      pointer-events: none;
    }
  `,
  Spacing: styled.div`
    height: 42px;
  `,
};

type TBodyProps = {
  loading?: boolean;
};

const Body: FC<TBodyProps> = ({ loading }) => {
  const {
    state: { rounds },
  } = useTalk();

  const ref = useRef<HTMLDivElement>(null);

  setTimeout(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, 600);

  return (
    <S.Main disableFooter={!rounds[rounds.length - 1].question?.responseType}>
      <S.ScrollView id='main' ref={ref}>
        {loading && <LoadingQuestion />}

        <S.Spacing />

        {rounds.map(({ question, answer }, index) => {
          const responses =
            question &&
            question?.question.labels.map(item => (
              <Question key={Math.random()} label={item} type={question.flow} />
            ));

          const options = question?.question.options && question.question.options.component && (
            <Interceptor
              type={question.question.options.type}
              data={question.question.options.data}
              component={question.question.options.component}
            />
          );

          const requests = answer && (
            <Response
              label={String(answer.value)}
              editable={!!question?.editable}
              field={question?.editable?.field ?? ''}
            />
          );

          const isButtonWithSearch =
            question?.question.options &&
            question?.question.options?.component ===
              QuestionComponentEnum.SELECT_BUTTON_WITH_SEARCH;

          const showOptions = isButtonWithSearch && rounds.length - 1 !== index ? false : true;

          return (
            <div className='round' key={Math.random()}>
              {responses}
              {(showOptions || question?.question.options.type === 14) && options}
              {requests}
            </div>
          );
        })}
      </S.ScrollView>
    </S.Main>
  );
};

export default Body;
