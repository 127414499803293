import { FC } from 'react';

import styled from 'styled-components';
import Button from '../../../atoms/Button';
import Text from '../../../atoms/Text';

interface CardProps {
  onClick?: () => void;
  imagePath?: string;
  title?: string;
  text?: string;
  subtitle?: string;
  actionText?: string;
  hasImage?: boolean;
}

const S = {
  Container: styled.div`
    background: #f0f0f0;
    border-radius: 16px;
    position: relative;
    margin: 0;
    width: 327px;
    overflow: hidden;
    margin-bottom: 18px;

    .head {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 122px;
    }

    img {
      width: 120px;
      height: 120px;
      padding: 0 40px;
      border-bottom: 0.5px solid #ccc;
    }

    .body {
      padding: 0 16px;
      margin: 18px 0;
    }

    .footer {
      padding: 0 16px 16px;
    }
  `,
};

const Card: FC<CardProps> = ({ onClick, title, text, actionText, hasImage, imagePath }) => {
  return (
    <S.Container>
      <div className='head'>{hasImage && <img src={imagePath} alt={title} />}</div>

      <div className='body'>
        {title && (
          <Text fontSize='12px' color='#444444' lineHeight='14.52px' margin='0 0 16px' bold>
            {title}
          </Text>
        )}
        {text && (
          <Text fontSize='12px' color='#767676' lineHeight='14.52px'>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </Text>
        )}
      </div>

      {actionText && (
        <div className='footer'>
          <Button onClick={onClick} full>
            {actionText}
          </Button>
        </div>
      )}
    </S.Container>
  );
};

export default Card;
