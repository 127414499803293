import { createRoot } from 'react-dom/client';

import App from './App';

window.onload = () => {
  const elm = window.document.createElement('div');

  const id = 'santander-auto-chatbot';

  elm.setAttribute('id', id);

  window.document.body.appendChild(elm);

  const container = document.getElementById(id);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(<App />);
};
