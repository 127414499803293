import { FC, useState } from 'react';

import styled from 'styled-components';
import client from '../../../api';
import config from '../../../api/config';
import { useDialog } from '../../../contexts/dialog';
import { DialogTypeEnum } from '../../../contexts/dialog/types';
import { useTalk } from '../../../contexts/talk';
import mocks, { OfferType } from '../../../helpers/mocks';
import Button from '../Button';
import Text from '../Text';

interface OfferProps {
  onSelect?: (value: string | number | boolean, answer?: string) => void;
  imagePath: string;
  title: string;
  subtitle: string;
  id: number;
  offer?: string;
}

interface IBrandList {
  onSelect?: () => void;
}

const S = {
  Container: styled.div`
    padding: 16px;
    margin: 0;
    margin-bottom: 18px;
    background: #f0f0f0;
    border-radius: 16px;
    position: relative;

    img {
      max-width: 90px;
      max-height: 52px;
    }

    .mt6 {
      margin-top: 12px;
    }

    .flex {
      display: flex;
    }

    .items-start {
      align-items: flex-start;
    }

    .items-end {
      align-items: flex-end;
    }

    .items-center {
      align-items: center;
    }

    .content-start {
      justify-content: flex-start;
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .head {
      height: 52px;
    }
  `,
  Tag: styled.div`
    height: 20px;
    border-radius: 12px;
    background-color: #fff;
    padding: 0 10px;
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 16px;
  `,
};

const List = styled.div``;

const OfferCard: FC<OfferProps> = ({ onSelect, imagePath, title, offer, id }) => {
  const { handleDialog } = useDialog();

  return (
    <S.Container>
      <div className='flex items-center head'>
        <img src={imagePath} alt={title} />

        <div>
          <Text fontSize='14px' color='#222222' lineHeight='125%' bold>
            {offer || 'Terceiros'}
          </Text>
          <Text fontSize='10px' color='#767676' lineHeight='150%'>
            {title}
          </Text>
        </div>
      </div>

      {/* <div className='head mt6'>
        <div className='flex content-start items-end'>
          <Text fontSize='14px' color='#222' lineHeight='30px'>
            R$
          </Text>

          <Text fontSize='20px' color='#222' bold lineHeight='30px'>
            120,00*
          </Text>

          <Text fontSize='14px' color='#222' lineHeight='30px'>
            mensal
          </Text>
        </div>

        <Text fontSize='12px' color='#444' lineHeight='15px'>
          Valor total do seguro: R$ 2.772,00
        </Text>
      </div> */}

      <div className='flex mt6'>
        <Button
          onClick={() =>
            handleDialog({
              type: DialogTypeEnum.OFFER,
              componentProps: {
                id: 1,
              },
            })
          }
          border='1px solid #CC0000'
          bgColor='transparent'
          margin='0 6px 0 0'
          color='#CC0000'
          full
        >
          Ver detalhes
        </Button>

        <Button onClick={() => onSelect?.(1, 'Cobertura completa')} margin='0 0 0 6px' full>
          Escolher
        </Button>
      </div>

      {id === 2 && (
        <S.Tag className='flex items-center'>
          <Text fontSize='10px' color='#222222' lineHeight='150%'>
            Recomendado
          </Text>
        </S.Tag>
      )}
    </S.Container>
  );
};

const OfferCardList: FC<IBrandList> = () => {
  const offers: OfferType[] = mocks.offers;

  const { handleDialog } = useDialog();

  const { appendRound } = useTalk();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  const handleAction = async (value: string | number | boolean, answer?: string) => {
    setLoading(true);

    answer &&
      appendRound({
        answer: {
          id: Math.random(),
          editable: false,
          value: answer,
        },
      });

    const xKey = localStorage.getItem(config.localStorage.xKey);

    try {
      const { data } = await client.patch(`/chats/${xKey}`, {
        data: value,
      });

      appendRound({ question: data });

      handleDialog({
        type: DialogTypeEnum.TALK,
        componentProps: {
          id: null,
        },
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <List>
      {offers.map(({ id, imagePath, title, subtitle, offer }) => (
        <OfferCard
          key={id}
          id={id}
          onSelect={handleAction}
          imagePath={imagePath}
          title={title}
          subtitle={subtitle}
          offer={offer}
        />
      ))}
    </List>
  );
};

export default OfferCardList;
