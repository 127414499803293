import { FC } from 'react';
import { QuestionResponseTypeEnum } from '../../../../../../contexts/talk/types';
import TextFieldComponent from '../../../../../atoms/TextField/TextField';

type TTextFieldProps = {
  handleAction?: (value: string | number, answer: string) => void;
  mask?: string;
  placeholder?: string;
  returnType?: QuestionResponseTypeEnum;
};

const TextField: FC<TTextFieldProps> = props => {
  const { handleAction, mask, placeholder, returnType } = props;

  const handleClick = (value: string, answer: string) => {
    let newValue;
    switch (returnType) {
      case QuestionResponseTypeEnum.INT:
        newValue = parseInt(value);
        break;

      default:
        newValue = value;
        break;
    }

    if (handleAction) {
      handleAction(newValue, answer);
    }
  };

  return <TextFieldComponent mask={mask} placeholder={placeholder} onSubmit={handleClick} />;
};

export default TextField;
