import { FC, memo } from 'react';
import { useDialog } from '../../../contexts/dialog';
import { DialogTypeEnum } from '../../../contexts/dialog/types';

import Default from './Default';
import Offer from './Offer';

const Frame: FC = () => {
  const { dialogState } = useDialog();

  const type = dialogState.type;

  switch (type) {
    case DialogTypeEnum.OFFER:
      return <Offer />;

    default:
      return <Default />;
  }
};

export default memo(Frame);
