import * as React from 'react';

import { TalkContextPayload } from './types';

const TalkContext = React.createContext({} as TalkContextPayload);

export const TalkProvider = TalkContext.Provider;

export function useTalk(): TalkContextPayload {
  const context = React.useContext(TalkContext);

  if (!context) {
    throw new Error('useTalk should be within TalkProvider');
  }

  return context;
}
