import { FC } from 'react';
import styled from 'styled-components';
import Text from '../../../../../../../atoms/Text';

interface IProps {
  value: string | number;
  onClick?: (item: string | number) => void;
}
const S = {
  Message: styled.button`
    background: #fff;
    padding: 8px 16px;
    border-radius: 16px;
    display: inline-flex;
    margin-bottom: 8px;
    margin-right: 8px;
    border: 1px solid #ccc;
    cursor: pointer;
    &:hover {
      background: #f0f0f0;
    }

    > div {
      line-height: 150%;
      margin: 0 !important;
    }
  `,
};

const OptionsItem: FC<IProps> = ({ value, onClick }) => {
  return (
    <S.Message onClick={() => onClick?.(value)}>
      <Text color='#767676' fontSize='10px' bold>
        <div dangerouslySetInnerHTML={{ __html: String(value) }} />
      </Text>
    </S.Message>
  );
};

export default OptionsItem;
