import { ChangeEventHandler, FC, memo, useState } from 'react';

import MaskedInput, { Mask } from 'react-text-mask';
import { CEP_MASK, CPF_MASK, DATE_MASK, PHONE_MASK } from './masks';
import { validate } from './validate';
import S from './styles';
import { useTalk } from '../../../contexts/talk';

interface TextFieldProps {
  mask?: string;
  defaultValue?: string;
  placeholder?: string;
  onSubmit?: (value: string, answer: string) => void;
  onChange?: (value: string) => void;
}

const TextSearchField: FC<TextFieldProps> = ({ mask = '', placeholder, onSubmit }) => {
  const { state, setSearch } = useTalk();

  const [valid, setValid] = useState<boolean | null>();

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { target } = event;

    const isValid = validate(mask, target.value);

    setValid(isValid);

    setSearch(target.value);
  };

  let inputMask: Mask = [];

  switch (mask) {
    case 'cpf':
      inputMask = CPF_MASK;
      break;

    case 'date':
      inputMask = DATE_MASK;
      break;

    case 'phone':
      inputMask = PHONE_MASK;
      break;

    case 'zipcode':
      inputMask = CEP_MASK;
      break;

    case 'email':
      inputMask = [];
      break;

    case 'fullname':
      inputMask = [];
      break;

    default:
      inputMask = [];
  }

  let input;

  if (mask && inputMask) {
    input = (
      <MaskedInput
        value={state.search}
        onChange={handleChange}
        mask={inputMask}
        placeholder={placeholder}
        id='textField'
        showMask
        guide
      />
    );
  } else {
    input = (
      <input
        value={state.search}
        onChange={handleChange}
        placeholder={placeholder}
        autoComplete='off'
        id='textField'
        type='text'
      />
    );
  }

  return (
    <S.Main>
      <S.Input>
        {input}
        {onSubmit && (
          <S.Button disabled={!valid} type='button'>
            <svg
              width='25'
              height='24'
              viewBox='0 0 25 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.25308 17.9901C4.24999 17.996 4.24999 18 4.24999 18L20.25 11.995L4.25308 6L6.74469 11.6044C6.85579 11.8543 6.85579 12.1368 6.74469 12.3857L4.25308 17.9901ZM4.29527 20C3.75209 20 3.22016 19.7883 2.82632 19.3926C2.25143 18.8161 2.09083 17.9652 2.41612 17.2276H2.41715L4.72286 11.999L2.41715 6.7714C2.09083 6.03284 2.25143 5.18294 2.82632 4.6064C3.40122 4.02886 4.27072 3.84596 5.03895 4.13523L20.9447 10.1461C21.7385 10.4453 22.25 11.173 22.25 11.999C22.25 12.8251 21.7385 13.5527 20.9447 13.8519L5.03895 19.8638C4.79652 19.9553 4.54487 20 4.29527 20Z'
                fill='#777E91'
              />
            </svg>
          </S.Button>
        )}
      </S.Input>
    </S.Main>
  );
};

export default memo(TextSearchField);
