import * as React from 'react';

import { DialogContextPayload } from './types';

const DialogContext = React.createContext({} as DialogContextPayload);

export const DialogProvider = DialogContext.Provider;

export function useDialog(): DialogContextPayload {
  const context = React.useContext(DialogContext);

  if (!context) {
    throw new Error('useDialog should be within DialogProvider');
  }

  return context;
}
