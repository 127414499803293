export interface BrandType {
  id: number;
  name: string;
  imagePath: string;
}

export interface OfferType {
  id: number;
  title: string;
  subtitle: string;
  offer: string;
  imagePath: string;
  category: string;
  year: number;
  price: {
    insurance: number;
    franchise: number;
    contract: number;
  };
  period: string;
}

export interface MocksTypes {
  brands: BrandType[];
}

export default {
  brands: [
    {
      id: 1,
      name: 'Fiat',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/fiat.webp',
    },
    {
      id: 2,
      name: 'Chevrolet',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/chevrolet.webp',
    },
    {
      id: 3,
      name: 'Toyota',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/toyota.webp',
    },
    {
      id: 4,
      name: 'Hyundai',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/hyundai.webp',
    },
    {
      id: 5,
      name: 'Volkswagen',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/volkswagen.webp',
    },
    {
      id: 6,
      name: 'Jeep',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/jeep.webp',
    },
    {
      id: 7,
      name: 'Renault',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/renault.webp',
    },
    {
      id: 8,
      name: 'Honda',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/honda.webp',
    },
    {
      id: 9,
      name: 'Nissan',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/nissan.webp',
    },
    {
      id: 10,
      name: 'Chery',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/chery.webp',
    },
    {
      id: 11,
      name: 'Peugeot',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/peugeot.webp',
    },
    {
      id: 12,
      name: 'Citroen',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/citroen.webp',
    },
    {
      id: 13,
      name: 'Honda',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/honda.webp',
    },
    {
      id: 14,
      name: 'Nissan',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/nissan.webp',
    },
    {
      id: 15,
      name: 'Chery',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/chery.webp',
    },
    {
      id: 16,
      name: 'Peugeot',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/peugeot.webp',
    },
    {
      id: 17,
      name: 'Citroen',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/citroen.webp',
    },
    {
      id: 18,
      name: 'Honda',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/honda.webp',
    },
    {
      id: 19,
      name: 'Nissan',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/nissan.webp',
    },
    {
      id: 20,
      name: 'Chery',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/chery.webp',
    },
    {
      id: 21,
      name: 'Peugeot',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/peugeot.webp',
    },
    {
      id: 22,
      name: 'Citroen',
      imagePath: 'https://www.webmotors.com.br/catalogo/assets/img/logos/citroen.webp',
    },
  ],
  offers: [
    {
      id: 1,
      offer: 'Terceiros',
      title: 'Honda Civic',
      subtitle: '2.0 Flexone EX 2020',
      year: 2020,
      imagePath:
        'https://www.webmotors.com.br/imagens/prod/348100/HONDA_CIVIC_2.0_16V_FLEXONE_LX_4P_CVT_34810002594121889.webp?s=fill&w=440&h=330&q=80&t=true',
      category: 'Completo',
      price: {
        insurance: 9500,
        franchise: 4500,
        contract: 231,
      },
      period: 'mensal',
      included: [
        {
          type: 1,
          value: 'Assistências 24h',
        },
        {
          type: 2,
          value: 'Guincho até 400km',
        },
        {
          type: 3,
          value: 'Carro reserva',
        },
        {
          type: 4,
          value: 'Cobertura de vidros',
        },
      ],
      notIncluded: [
        {
          type: 5,
          value: 'Despesas Hospitalares',
        },
        {
          type: 6,
          value: 'Despesas Extras',
        },
      ],
      benefits: [
        'Você pode incluir o valor da mensalidade do seguro no boleto do financiamento do seu carro.',
        'Conta com o plano de benefícios e descontos em vários estabelecimentos.',
        'Contrata com apenas um clique, sem burocracia.',
      ],
    },
    {
      id: 2,
      offer: 'Completa',
      title: 'Honda Civic',
      subtitle: '2.0 Flexone EX 2020',
      year: 2020,
      imagePath:
        'https://www.webmotors.com.br/imagens/prod/348100/HONDA_CIVIC_2.0_16V_FLEXONE_LX_4P_CVT_34810002594121889.webp?s=fill&w=440&h=330&q=80&t=true',
      category: 'Completo',
      price: {
        insurance: 9500,
        franchise: 4500,
        contract: 231,
      },
      period: 'mensal',
      included: [
        {
          type: 1,
          value: 'Assistências 24h',
        },
        {
          type: 2,
          value: 'Guincho até 400km',
        },
        {
          type: 3,
          value: 'Carro reserva',
        },
        {
          type: 4,
          value: 'Cobertura de vidros',
        },
      ],
      notIncluded: [
        {
          type: 5,
          value: 'Despesas Hospitalares',
        },
        {
          type: 6,
          value: 'Despesas Extras',
        },
      ],
      benefits: [
        'Você pode incluir o valor da mensalidade do seguro no boleto do financiamento do seu carro.',
        'Conta com o plano de benefícios e descontos em vários estabelecimentos.',
        'Contrata com apenas um clique, sem burocracia.',
      ],
    },
  ],
};

// const rounds = [
//   {
//     question: {
//       question: {
//         labels: [
//           'Seja bem vindo ao chat do seu seguro',
//           'Aqui você vai encontrar de tudo para te ajudar :)',
//           'Poderia me informar o seu e-mail?',
//         ],
//         options: [],
//       },
//       responseType: {
//         type: 2,
//         mask: 1,
//         min: 10,
//         max: 120,
//         isCollection: false,
//       },
//     },
//     answer: {
//       value: 'caiobatistame@gmail.com',
//       editable: false,
//     },
//   },
//   {
//     question: {
//       question: {
//         labels: ['Apresentação'],
//         options: [],
//       },
//       responseType: {
//         type: 2,
//         mask: 1,
//         min: 10,
//         max: 120,
//         isCollection: false,
//       },
//       flow: 2,
//     },
//   },
// ] as Round[];

// const rounds = [
//   {
//     question: {
//       question: {
//         labels: [
//           'Seja bem vindo ao chat do seu seguro',
//           'Aqui você vai encontrar de tudo para te ajudar :)',
//           'Poderia me informar o seu e-mail?',
//         ],
//         options: [],
//       },
//       response: { type: 2, mask: 1, min: 10, max: 120, isCollection: false },
//     },
//     answer: {
//       value: 'caiobatistame@gmail.com',
//       editable: false,
//     },
//   },
//   {
//     question: {
//       question: {
//         labels: ['Apresentação'],
//         options: [],
//       },
//       response: { type: 2, mask: 1, min: 10, max: 120, isCollection: false },
//       // flow: 1,
//     },
//   },
// ] as Round[];
