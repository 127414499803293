import { FC } from 'react';
import styles from './style.module.scss';

const LoadingQuestion: FC = () => (
  <div className={styles.loadContainer}>
    <div className={styles.loadItem}>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default LoadingQuestion;
