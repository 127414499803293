import { FC } from 'react';
import styled from 'styled-components';
import client from '../../../../../../../api';
import config from '../../../../../../../api/config';
import { useTalk } from '../../../../../../../contexts/talk';
import { QuestionOptionsTypeEnum, QuestionRound } from '../../../../../../../contexts/talk/types';
import Text from '../../../../../../atoms/Text';
import OptionsItem from '../Options/Item';

interface IItem {
  id: string;
  name: string;
  slugged: string;
}

interface IProps {
  items: Array<IItem>;
  type?: QuestionOptionsTypeEnum;
}
const S = {
  Box: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
  `,
};

const OptionsWithSearch: FC<IProps> = ({ items, type }) => {
  const { appendRound, state, setSearch } = useTalk();

  const isArrayNumber = type === QuestionOptionsTypeEnum.ARRAY_NUMBER;

  const itemsFormatted = items.map(item => ({
    id: item ?? '',
    name: item ?? '',
    slugged: item ?? '',
  }));

  const handleClick = async (item: IItem) => {
    const key = localStorage.getItem(config.localStorage.xKey);

    appendRound({
      answer: {
        value: item?.name,
        id: Math.random(),
      },
    });

    try {
      const { data } = await client.patch<QuestionRound>(`/chats/${key}`, {
        data: item.slugged,
      });

      appendRound({ question: data });

      setSearch('');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const list = (isArrayNumber ? itemsFormatted : items) as Array<IItem>;

  const filtredList = list.filter(item => {
    const name = String(item.name).toLowerCase();

    return state.search ? name.includes(state?.search.toLowerCase()) : item;
  });

  return (
    <S.Box className='list'>
      {filtredList.slice(0, 10).map(item => (
        <OptionsItem
          key={Math.random()}
          value={String(typeof item === 'object' ? item.name : item)}
          onClick={() => handleClick(item)}
        />
      ))}

      {!filtredList.length && (
        <Text fontSize='12px' color='#737373' lineHeight='125%' margin='10px'>
          Não encontramos o termo buscado
        </Text>
      )}
    </S.Box>
  );
};

export default OptionsWithSearch;
