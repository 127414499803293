import { useCallback, useMemo, useState } from 'react';
import {
  DialogContainerProps,
  DialogContextPayload,
  DialogState,
  DialogTypeEnum,
  HandleDialogOptions,
  SizesEnum,
} from './types';
import { DialogProvider } from '.';
import FrameControl from '../../components/molecules/FrameControl';

function DialogContainer({ children }: DialogContainerProps): React.ReactElement {
  const defaultState = useMemo<DialogState>(
    () => ({
      size: SizesEnum.MD,
      onClose: undefined,
      colorScheme: 'secondary',
      type: DialogTypeEnum.TALK,
      componentProps: {},
      opened: true,
    }),
    [],
  );

  const [dialogState, setDialogState] = useState<DialogState>(defaultState);

  const handleDialog = useCallback(
    (options?: HandleDialogOptions) => {
      const dialogOptions = options as HandleDialogOptions;

      const newState = {
        ...dialogState,
        ...dialogOptions,
      };

      setDialogState(newState);
    },
    [dialogState],
  );

  const payload = useMemo<DialogContextPayload>(
    () => ({
      handleDialog,
      dialogState,
    }),
    [handleDialog, dialogState],
  );

  return (
    <DialogProvider value={payload}>
      <FrameControl />
      {children}
    </DialogProvider>
  );
}

export default DialogContainer;
