import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    primaryColor: string;
    primaryLightColor: string;
    primaryDarkColor: string;
    secondaryColor: string;
    secondaryLightColor: string;
    secondaryDarkColor: string;
    grayDarkestColor: string;
    grayDarkColor: string;
    grayColor: string;
    grayLightColor: string;
    grayLightestColor: string;
    fontFamily: string;
  }
}

const fontFamily =
  'Inter, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"';

export const lightTheme: DefaultTheme = {
  primaryColor: '#CC0000',
  primaryLightColor: '#FF8686',
  primaryDarkColor: '#A10C0C',
  secondaryColor: '#75B2AA',
  secondaryLightColor: '#D8E9E7',
  secondaryDarkColor: '',
  grayDarkestColor: '#353945',
  grayDarkColor: '#777E90',
  grayColor: '#767676',
  grayLightColor: '#E6E8EC',
  grayLightestColor: '#F6F6F6',
  fontFamily,
};

export const darkTheme: DefaultTheme = {
  primaryColor: '#CC0000',
  primaryLightColor: '#CC0000',
  primaryDarkColor: '#CC0000',
  secondaryColor: '#666',
  secondaryLightColor: '#666',
  secondaryDarkColor: '#666',
  grayDarkestColor: '',
  grayDarkColor: '',
  grayColor: '',
  grayLightColor: '',
  grayLightestColor: '',
  fontFamily,
};
