import { FC, memo } from 'react';
import styled from 'styled-components';
import { useDialog } from '../../../../contexts/dialog';
import Body from './Body';
import Footer from './Footer';
import Header from './Header';

const S = {
  Container: styled.div<{ opened?: boolean }>`
    display: ${({ opened }) => (opened ? 'block' : 'none')};
    height: 812px;
    width: 375px;
    background: #ecedf2;
    position: fixed;
    z-index: 8;
    right: 25px;
    bottom: 90px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 0px 6px -4px #000;
  `,
};

const Default: FC = () => {
  const { dialogState } = useDialog();

  if (!dialogState.opened) return null;

  return (
    <S.Container opened={dialogState.opened}>
      <Header />
      <Body />
      <Footer />
    </S.Container>
  );
};

export default memo(Default);
