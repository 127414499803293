import { FC } from 'react';
import styled from 'styled-components';
import client from '../../../../../../../api';
import config from '../../../../../../../api/config';
import { useTalk } from '../../../../../../../contexts/talk';
import { KeyValue, QuestionRound } from '../../../../../../../contexts/talk/types';
import OptionsItem from './Item';

interface IProps {
  items: Array<KeyValue>;
}
const S = {
  Box: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
  `,
};

const Options: FC<IProps> = ({ items }) => {
  const { appendRound } = useTalk();

  const handleClick = async (
    item: string | number | boolean,
    answer: string | number | boolean,
  ) => {
    const key = localStorage.getItem(config.localStorage.xKey);

    appendRound({
      answer: {
        value: String(answer),
        id: Math.random(),
      },
    });

    try {
      const { data } = await client.patch<QuestionRound>(`/chats/${key}`, { data: item });

      appendRound({ question: data });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  return (
    <S.Box>
      {items.map(item => (
        <OptionsItem
          key={String(item.key)}
          value={String(item.value)}
          onClick={() => handleClick(item.key, item.value)}
        />
      ))}
    </S.Box>
  );
};

export default Options;
