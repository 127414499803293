export type OnClose = (payload?: Record<string, unknown>) => void;

export type DialogContainerProps = React.PropsWithChildren;

export interface DialogPayloadState {
  isOpen: boolean;
}

export enum SizesEnum {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum DialogTypeEnum {
  OFFER = 'Offer',
  TALK = 'Talk',
}
export interface DialogState<T = Record<string, unknown>> {
  size?: SizesEnum;
  onClose?: OnClose;
  colorScheme?: string;
  type?: DialogTypeEnum;
  componentProps?: T | Record<string, unknown>;
  opened?: boolean;
}

export type HandleDialogOptions = DialogState;

export type DialogContextPayload = {
  handleDialog: (options?: HandleDialogOptions) => void;
  dialogState: DialogState;
};
