import styled from 'styled-components';

interface TProps {
  as?: string;
  color?: string;
  margin?: string;
  bold?: boolean;
  semiBold?: boolean;
  transform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase' | 'initial' | 'inherit';
  fontSize?: string;
  lineHeight?: string;
}

const Text = styled.div<TProps>`
  padding: 0px;
  white-space: pre-wrap;
  box-sizing: border-box;
  color: ${({ color, theme }) => color ?? theme.grayColor};
  margin: ${({ margin }) => margin ?? '0'};
  line-height: ${({ lineHeight }) => lineHeight ?? '1'};
  text-transform: ${({ transform }) => transform};
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ bold }) => (bold ? 'bold' : '400')};
`;

export default Text;
