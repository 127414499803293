import { FC, useState } from 'react';
import styled from 'styled-components';
import client from '../../../../../api';
import config from '../../../../../api/config';
import { useDialog } from '../../../../../contexts/dialog';
import { DialogTypeEnum } from '../../../../../contexts/dialog/types';
import { useTalk } from '../../../../../contexts/talk';
import Button from '../../../../atoms/Button';
import Icon from '../../../../atoms/Icon';
import Text from '../../../../atoms/Text';

const product = {
  id: 1,
  title: 'Honda Civic',
  subtitle: '2.0 Flexone EX 2020',
  imagePath:
    'https://image.webmotors.com.br/_fotos/anunciousados/gigante/2022/202209/20220905/honda-civic-2.0-16v-flexone-exl-4p-cvt-wmimagem15493188464.jpg?s=fill&w=1920&h=1440&q=75',
  category: 'Completo',
  price: {
    insurance: 9500,
    franchise: 4500,
    contract: 231,
  },
  period: 'mensal',
  included: [
    {
      type: 1,
      value: 'Assistências 24h',
    },
    {
      type: 2,
      value: 'Guincho até 400km',
    },
    {
      type: 3,
      value: 'Carro reserva',
    },
    {
      type: 4,
      value: 'Cobertura de vidros',
    },
  ],
  notIncluded: [
    {
      type: 5,
      value: 'Despesas Hospitalares',
    },
    {
      type: 6,
      value: 'Despesas Extras',
    },
  ],
  benefits: [
    'Você pode incluir o valor da mensalidade do seguro no boleto do financiamento do seu carro.',
    'Conta com o plano de benefícios e descontos em vários estabelecimentos.',
    'Contrata com apenas um clique, sem burocracia.',
  ],
};

const S = {
  Main: styled.div`
    overflow-y: scroll;
    position: relative;
    background: #fff;
    height: 757px;

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .items-start {
      align-items: flex-start;
    }

    .items-end {
      align-items: flex-end;
    }

    .content-start {
      justify-content: flex-start;
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .hr {
      height: 1px;
      width: 100%;
      background-color: #dfe7ed;
    }

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track-piece,
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  `,
  View: styled.div`
    height: 100%;
  `,
  Image: styled.img`
    width: 100%;
    height: 223px;
    object-fit: cover;
  `,
  Content: styled.div`
    padding: 24px;
  `,
  Box: styled.div`
    margin-top: 21px;
  `,
  List: styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 21px;

    li {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      height: 24px;
    }
  `,
};

type TBodyProps = {
  loading?: boolean;
};

const Body: FC<TBodyProps> = () => {
  const { appendRound } = useTalk();
  const { handleDialog } = useDialog();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  const handleAction = async (value: string | number | boolean, answer?: string) => {
    setLoading(true);

    answer &&
      appendRound({
        answer: {
          id: Math.random(),
          editable: false,
          value: answer,
        },
      });

    const xKey = localStorage.getItem(config.localStorage.xKey);

    try {
      const { data } = await client.patch(`/chats/${xKey}`, {
        data: value,
      });

      appendRound({ question: data });

      handleDialog({
        type: DialogTypeEnum.TALK,
        componentProps: {
          id: null,
        },
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <S.Main>
      <S.View>
        <S.Image src={product.imagePath} />
        <S.Content>
          <Text fontSize='20px' bold color='#CC0000' margin='0 0 8px'>
            {product.category}
          </Text>
          <Text fontSize='12px' color='#444' margin='0 0 8px'>
            {product.title} {product.subtitle}
          </Text>

          <div className='flex'>
            <Text fontSize='12px' color='#444' lineHeight='14.5px'>
              Valor total do seguro:
            </Text>
            <Text fontSize='12px' color='#444' margin='0 0 8px'>
              R$ {product.price.insurance}
            </Text>
          </div>

          <div className='flex'>
            <Text fontSize='12px' color='#444' lineHeight='14.5px'>
              Valor total do franquia:
            </Text>
            <Text fontSize='12px' color='#444'>
              R$ {product.price.franchise}
            </Text>
          </div>

          <S.Box>
            <Text fontSize='10px' color='#222' margin='0 0 4px'>
              Desde:
            </Text>

            <div className='flex content-start items-end'>
              <Text fontSize='14px' color='#222' lineHeight='30px'>
                R$
              </Text>

              <Text fontSize='20px' color='#222' bold lineHeight='30px'>
                {product.price.contract},00*
              </Text>

              <Text fontSize='14px' color='#222' lineHeight='30px'>
                {product.period}
              </Text>
            </div>
          </S.Box>

          <S.Box>
            <Text fontSize='16px' color='#222' bold>
              Inclui
            </Text>
          </S.Box>

          <S.Box>
            <S.List className='flex flex-wrap'>
              {product.included.map(item => (
                <li key={item.type}>
                  <Icon name='car' color='#CC0000' />

                  <Text fontSize='10px' color='#444' lineHeight='15px' margin='0 0 0 6px'>
                    {item.value}
                  </Text>
                </li>
              ))}
            </S.List>
          </S.Box>

          <S.Box>
            <Text fontSize='16px' color='#222' bold>
              Não inclui
            </Text>
          </S.Box>

          <S.Box>
            <S.List className='flex flex-wrap'>
              {product.notIncluded.map(item => (
                <li key={item.type}>
                  <Icon name='car' color='#CC0000' />

                  <Text fontSize='10px' color='#444' lineHeight='15px' margin='0 0 0 6px'>
                    {item.value}
                  </Text>
                </li>
              ))}
            </S.List>
          </S.Box>

          <div className='hr' />

          <S.Box>
            <Text fontSize='16px' color='#222' bold>
              Beneficios:
            </Text>
          </S.Box>

          <S.Box>
            <ul>
              {product.benefits.map(item => (
                <li key={item}>
                  <Text fontSize='14px' color='#444' lineHeight='21px'>
                    {item}
                  </Text>
                </li>
              ))}
            </ul>
          </S.Box>
          <S.Box>
            <Text fontSize='8px' color='#444' lineHeight='12px'>
              (*) Os valores dessa contratação podem variar após a vistoria
            </Text>
          </S.Box>

          <S.Box>
            <Button onClick={() => handleAction(1, 'Cobertura completa')} full>
              Escolher plano
            </Button>
          </S.Box>
        </S.Content>
      </S.View>
    </S.Main>
  );
};

export default Body;
