import { FC } from 'react';
import { useTalk } from '../../../contexts/talk';
import { QuestionResponseFlowEnum } from '../../../contexts/talk/types';

import Card from './Card';
import Option from './Option';

const Presentation: FC = () => {
  const { appendRound } = useTalk();

  return (
    <div>
      <Card
        hasImage
        text='Olá somos a Santander Auto 👋<br/><br/>Estamos aqui para cuidar do que mais importa: você e seus sonhos.<br/><br/>Com o Santander Auto você contrata o seu seguro da forma mais fácil, junto com o financiamento do seu veículo 🚗 💰.'
        actionText='Conheça nosso diferencial'
        onClick={() =>
          appendRound({
            question: {
              question: {
                labels: ['Skills'],
                options: {
                  data: [],
                },
              },
              responseType: null,
              flow: QuestionResponseFlowEnum.SKILLS,
              editable: null,
            },
          })
        }
      />

      <Option
        icon='world-like'
        text='Acesse nosso site e conheça nossos serviços e vantagens 💻 .'
        actionText='Acessar ao site'
      />

      <Option
        icon='world-like'
        text='Acesse nosso site e conheça nossos serviços e vantagens 💻 .'
        actionText='Acessar ao site'
      />

      <Option
        icon='world-like'
        text='Acesse nosso site e conheça nossos serviços e vantagens 💻 .'
        actionText='Acessar ao site'
      />
    </div>
  );
};

export default Presentation;
