import {
  CEP_VALIDATE,
  EMAIL_VALIDATE,
  FULLNAME_VALIDATE,
  KILOMETERS_VALIDATE,
  PHONE_VALIDATE,
} from './masks';

export const validate = (mask: string, value: string) => {
  if (!mask) return value.length >= 3;

  if (mask === 'email') return EMAIL_VALIDATE.test(value);

  if (mask === 'phone') return PHONE_VALIDATE.test(value);

  if (mask === 'zipcode') return CEP_VALIDATE.test(value);

  if (mask === 'kilometers') return KILOMETERS_VALIDATE.test(value);

  if (mask === 'fullname') return FULLNAME_VALIDATE.test(value);

  return true;
};
