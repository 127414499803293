import { FC, memo, useEffect } from 'react';

import Card from './Card';

import digital from './digital.svg';
import assistance from './assistance.svg';
import coverage from './coverage.svg';
import { QuestionResponseFlowEnum } from '../../../contexts/talk/types';
import { useTalk } from '../../../contexts/talk';

const Skills: FC = () => {
  const { appendRound, state } = useTalk();

  const handleClick = () => {
    // eslint-disable-next-line no-console
    console.log('first');
  };

  const skills = [
    {
      title: 'Acompanhe tudo por meios digitais.',
      text: 'Estamos presentes em todos os canais  💻 📱 🤖 , nos comunicando de forma ágil e interligada para acompanhá-lo em cada passo da sua jornada.',
      image: digital,
    },
    {
      title: 'Assitencia 24H',
      text: 'Solicite assistência on-line a traves dos nosos canais digitais.',
      image: assistance,
    },
    {
      title: 'Coberturas personalizadas.',
      text: 'Oferecemos coberturas especiais para suas necessidades. Dessa forma você tem mais vantagens por um preço justo 😉 ⚖️',
      image: coverage,
      actionText: 'Faça uma cotação',
      onClick: handleClick,
    },
  ];

  const question = {
    labels: ['Posso te ajudar com alguma outra coisa?'],
    options: {
      data: [
        {
          key: false,
          value: 'Agora não',
        },
        {
          key: true,
          value: 'Bora',
        },
      ],
    },
  };

  useEffect(() => {
    if (state.rounds[state.rounds.length - 1].question?.flow === QuestionResponseFlowEnum.SKILLS) {
      setTimeout(() => {
        appendRound({
          question: {
            question,
            responseType: {
              type: 1,
              mask: 6,
            },
            flow: QuestionResponseFlowEnum.QOTE,
            editable: null,
          },
        });
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {skills.map(item => (
        <Card
          hasImage
          key={item.title}
          text={item.text}
          title={item.title}
          imagePath={item.image}
          actionText={item?.actionText}
        />
      ))}
    </div>
  );
};

export default memo(Skills);
