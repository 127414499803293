import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './evaluationMessages.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function EvaluationEmoji(props: any) {
  const { id, handleClick, setHover, hover } = props;

  const cardEmoji = classnames(style.evaluationEmoji);

  return (
    <button
      className={cardEmoji}
      type='button'
      onClick={() => handleClick && [handleClick(id), setHover(id)]}
      onMouseOver={() => setHover(id)}
    >
      <div className={style.image}>
        <svg
          width='41'
          height='41'
          viewBox='0 0 41 41'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_8163_727)'>
            <path
              d={
                id <= hover
                  ? 'M9.11849 38.7076C8.15349 39.2026 7.05849 38.3351 7.25349 37.2276L9.32849 25.4026L0.520992 17.0126C-0.301508 16.2276 0.125991 14.7926 1.22849 14.6376L13.4735 12.8976L18.9335 2.08013C19.426 1.10513 20.7585 1.10513 21.251 2.08013L26.711 12.8976L38.956 14.6376C40.0585 14.7926 40.486 16.2276 39.661 17.0126L30.856 25.4026L32.931 37.2276C33.126 38.3351 32.031 39.2026 31.066 38.7076L20.0885 33.0676L9.11599 38.7076H9.11849Z'
                  : 'M7.25349 37.225C7.05849 38.335 8.15349 39.2025 9.11849 38.7075L20.0935 33.0675L31.066 38.7075C32.031 39.2025 33.126 38.335 32.931 37.2275L30.856 25.4025L39.661 17.0125C40.486 16.2275 40.061 14.7925 38.956 14.6375L26.711 12.8975L21.251 2.08003C21.147 1.86075 20.9829 1.67549 20.7778 1.54576C20.5727 1.41603 20.3349 1.34717 20.0922 1.34717C19.8495 1.34717 19.6118 1.41603 19.4067 1.54576C19.2016 1.67549 19.0375 1.86075 18.9335 2.08003L13.4735 12.9L1.22849 14.64C0.125991 14.795 -0.301509 16.23 0.520992 17.015L9.32849 25.405L7.25349 37.23V37.225ZM19.516 30.3075L10.301 35.0425L12.036 25.15C12.0766 24.9223 12.0607 24.6881 11.9897 24.468C11.9186 24.2478 11.7946 24.0485 11.6285 23.8875L4.36349 16.9625L14.4935 15.5225C14.7033 15.4909 14.9022 15.4088 15.0733 15.2834C15.2444 15.158 15.3826 14.993 15.476 14.8025L20.0885 5.65753L24.706 14.8025C24.7994 14.993 24.9375 15.158 25.1087 15.2834C25.2798 15.4088 25.4787 15.4909 25.6885 15.5225L35.8185 16.96L28.5535 23.885C28.387 24.0463 28.2627 24.246 28.1917 24.4666C28.1206 24.6872 28.1049 24.9219 28.146 25.15L29.881 35.0425L20.666 30.3075C20.4878 30.2157 20.2902 30.1677 20.0897 30.1677C19.8893 30.1677 19.6917 30.2157 19.5135 30.3075H19.516Z'
              }
              fill='#EBA040'
            />
          </g>
          <defs>
            <clipPath id='clip0_8163_727'>
              <rect
                width='40'
                height='40'
                fill='white'
                transform='translate(0.0898438 0.0999756)'
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </button>
  );
}

EvaluationEmoji.defaultProps = {
  emoji: null,
  active: false,
  id: null,
  handleClick: null,
};

EvaluationEmoji.propTypes = {
  emoji: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
  active: PropTypes.bool,
  id: PropTypes.number,
  handleClick: PropTypes.func,
  hover: PropTypes.number,
  setHover: PropTypes.func,
};

export default EvaluationEmoji;
