import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { HandleTalkOptions, Round, State, TalkContextPayload } from './types';
import { TalkProvider } from '.';

function TalkContainer({ children }: PropsWithChildren): React.ReactElement {
  const defaultState = useMemo<State>(
    () => ({
      rounds: [],
      search: '',
    }),
    [],
  );

  const [state, setState] = useState<State>(defaultState);

  const setSearch = useCallback(
    (search: string) => {
      setState({
        ...state,
        search,
      });
    },
    [state],
  );

  const handleTalk = useCallback(
    (options?: HandleTalkOptions) => {
      const talkOptions = options as HandleTalkOptions;

      const newState = {
        ...state,
        ...talkOptions,
      };

      setState(newState);
    },
    [state],
  );

  const appendRound = useCallback(
    (round: Round) => {
      const newState = {
        ...state,
      };

      newState.rounds.push(round);

      setState(newState);
    },
    [state],
  );

  const payload = useMemo<TalkContextPayload>(
    () => ({
      handleTalk,
      appendRound,
      state,
      setSearch,
    }),
    [handleTalk, appendRound, state, setSearch],
  );

  return <TalkProvider value={payload}>{children}</TalkProvider>;
}

export default TalkContainer;
