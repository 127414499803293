import { FC, memo } from 'react';
import styled from 'styled-components';
import Body from './Body';
import Header from './Header';

const S = {
  Container: styled.div`
    width: 375px;
    height: 812px;
    background: #ecedf2;
    position: fixed;
    z-index: 8;
    right: 25px;
    bottom: 90px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 0px 6px -4px #000;
  `,
};

const Offer: FC = () => {
  return (
    <S.Container>
      <Header />
      <Body />
    </S.Container>
  );
};

export default memo(Offer);
