import styled from 'styled-components';
import { useDialog } from '../../../../../contexts/dialog';
import { DialogTypeEnum } from '../../../../../contexts/dialog/types';
import Text from '../../../../atoms/Text';

const S = {
  Main: styled.div``,
  Header: styled.div`
    height: 56px;
    background-color: #cc0000;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .container {
      padding: 0 24px 0;
      width: 100%;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .close {
      width: 40px;
      height: 40px;
      border: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      background: transparent;
    }

    .back {
      border: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      background: transparent;

      svg {
        margin-right: 16px;
      }
    }
  `,
  Loading: styled.div``,
};

function Header() {
  const { handleDialog } = useDialog();

  const handleClose = () =>
    handleDialog({
      type: DialogTypeEnum.TALK,
    });

  return (
    <S.Main>
      <S.Header>
        <div className='container flex'>
          <button type='button' className='flex back' onClick={handleClose}>
            <svg
              width='12'
              height='19'
              viewBox='0 0 12 19'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10.7148 17.2383L1.92174 8.97373L10.7148 1.26014'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>

            <Text fontSize='16px' color='white'>
              Voltar
            </Text>
          </button>

          {/* <button type='button' className='close flex'>
            <svg
              width='26'
              height='24'
              viewBox='0 0 26 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.0725 11.9508L22.0794 5.26974C22.5523 4.81881 22.5523 4.08992 22.0794 3.63898C21.6065 3.18805 20.842 3.18805 20.3691 3.63898L13.3622 10.32L6.35528 3.63898C5.88235 3.18805 5.11792 3.18805 4.64498 3.63898C4.17205 4.08992 4.17205 4.81881 4.64498 5.26974L11.6519 11.9508L4.64498 18.6318C4.17205 19.0828 4.17205 19.8116 4.64498 20.2626C4.88084 20.4875 5.19049 20.6005 5.50013 20.6005C5.80978 20.6005 6.11942 20.4875 6.35528 20.2626L13.3622 13.5815L20.3691 20.2626C20.605 20.4875 20.9146 20.6005 21.2242 20.6005C21.5339 20.6005 21.8435 20.4875 22.0794 20.2626C22.5523 19.8116 22.5523 19.0828 22.0794 18.6318L15.0725 11.9508Z'
                fill='white'
              />
            </svg>
          </button> */}
        </div>
      </S.Header>
    </S.Main>
  );
}

export default Header;
