import { FC } from 'react';

import styled from 'styled-components';
import Icon from '../../../atoms/Icon';
import sources from '../../../atoms/Icon/sources';
import Text from '../../../atoms/Text';

interface CardProps {
  onClick?: () => void;
  text?: string;
  actionText?: string;
  icon?: keyof typeof sources;
}

const S = {
  Container: styled.div`
    background: #f0f0f0;
    border-radius: 10px;
    position: relative;
    margin: 0;
    overflow: hidden;
    display: flex;
    padding: 16px;
    margin-bottom: 18px;
    cursor: pointer;

    .body {
      width: 100%;
      margin-left: 16px;
      flex-direction: column;
    }

    .head {
      width: 64px;
      min-width: 64px;
      height: 64px;
      border-radius: 32px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .flex {
      display: flex;
    }

    .items-center {
      align-items: center;
    }

    .content-center {
      justify-content: center;
    }
  `,
};

const Option: FC<CardProps> = ({ onClick, text, icon = 'world-like', actionText }) => {
  return (
    <S.Container onClick={onClick}>
      <div className='head'>
        <Icon name={icon} color='#CC0000' />
      </div>

      <div className='body flex content-center'>
        <Text fontSize='12px' color='#444444' lineHeight='150%'>
          {text}
        </Text>

        <Text fontSize='12px' color='#CC0000' lineHeight='150%' margin='4px 0 0' bold>
          <div className='flex items-center'>
            {actionText}
            <svg
              width='17'
              height='17'
              viewBox='0 0 17 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.05906 12.59L11.3924 9.25666C11.6531 8.99599 11.6531 8.57466 11.3924 8.31399L8.05906 4.98066C7.79839 4.71999 7.37706 4.71999 7.11639 4.98066C6.85572 5.24132 6.85572 5.66266 7.11639 5.92332L9.97839 8.78532L7.11639 11.6473C6.85572 11.908 6.85572 12.3293 7.11639 12.59C7.24639 12.72 7.41706 12.7853 7.58772 12.7853C7.75839 12.7853 7.92906 12.72 8.05906 12.59Z'
                fill='#CC0000'
              />
            </svg>
          </div>
        </Text>
      </div>
    </S.Container>
  );
};

export default Option;
