import { FC } from 'react';
import { useTalk } from '../../../../../../contexts/talk';
import { QuestionResponseTypeEnum } from '../../../../../../contexts/talk/types';
import TextFieldComponent from '../../../../../atoms/TextField/TextSearchField';

type TTextFieldProps = {
  handleAction?: (value: string | number, answer: string) => void;
  mask?: string;
  placeholder?: string;
  returnType?: QuestionResponseTypeEnum;
};

const TextFieldWithSearch: FC<TTextFieldProps> = props => {
  const { placeholder } = props;

  const { setSearch } = useTalk();

  const handleChange = (value: string) => {
    setSearch(value);
  };

  return <TextFieldComponent placeholder={placeholder} onChange={handleChange} />;
};

export default TextFieldWithSearch;
