import { FC, PropsWithChildren } from 'react';

import styled, { useTheme } from 'styled-components';
import Icon from '../Icon';
import Text from '../Text';

interface IProps extends PropsWithChildren {
  onClick?: () => void;
  bgColor?: string;
  color?: string;
  fontSize?: string;
  border?: string;
  margin?: string;
  height?: string;
  bold?: boolean;
  full?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const Container = styled.button<IProps>`
  width: ${({ full }) => (full ? '100%' : 'auto')};
  height: ${({ height }) => height ?? '40px'};
  margin: ${({ margin }) => margin ?? 0};
  background-color: ${({ theme, bgColor }) => bgColor ?? theme.primaryColor};
  border: ${({ border }) => border ?? 0};
  border-radius: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  .icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Button: FC<IProps> = ({
  onClick,
  bgColor,
  border,
  color,
  fontSize,
  bold,
  full,
  children,
  disabled,
  margin,
  height,
  loading,
}) => {
  const theme = useTheme();

  return (
    <Container
      onClick={onClick}
      bgColor={bgColor}
      full={full}
      disabled={loading || disabled}
      border={border}
      margin={margin}
      height={height}
    >
      {loading ? (
        <Icon name='loading' color='white' className='icon' />
      ) : (
        <Text
          lineHeight='18px'
          bold={bold}
          color={color ?? theme.grayLightestColor}
          fontSize={fontSize ?? '16px'}
        >
          {children}
        </Text>
      )}
    </Container>
  );
};

export default Button;
