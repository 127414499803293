type TProps = {
  color: string;
};

const Icon = ({ color = '#CC0000' }: TProps) => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_7782_2182)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.8957 14.3962C15.5526 14.2854 15.1881 14.2578 14.8323 14.3155C14.4764 14.3732 14.1394 14.5147 13.8489 14.7283C13.5585 14.9418 13.3229 15.2214 13.1617 15.5438C12.9479 15.9709 12.8738 16.4549 12.9502 16.9264C13.0266 17.3979 13.2495 17.8334 13.5872 18.1711L13.5982 18.1824L18.1709 22.9517L22.7435 18.1824L22.7544 18.1712C23.0922 17.8333 23.3151 17.3978 23.3916 16.9262C23.4682 16.4547 23.3945 15.971 23.181 15.5437C23.0196 15.2213 22.7841 14.9418 22.4936 14.7283C22.203 14.5147 21.8659 14.3732 21.51 14.3155C21.154 14.2578 20.7894 14.2855 20.4463 14.3962C20.1031 14.507 19.7912 14.6978 19.5362 14.9528L18.7012 15.7878C18.5605 15.9284 18.3698 16.0074 18.1709 16.0074C17.972 16.0074 17.7812 15.9284 17.6405 15.7878L16.8055 14.9528C16.5507 14.6978 16.2387 14.507 15.8957 14.3962ZM23.852 15.2084L24.5228 14.8732C24.877 15.5821 24.9992 16.3844 24.8723 17.1666C24.7457 17.9461 24.3781 18.6662 23.8211 19.2258L18.7122 24.5545C18.5708 24.702 18.3753 24.7854 18.1709 24.7854C17.9665 24.7854 17.7709 24.702 17.6295 24.5545L12.5206 19.2259C11.9635 18.6662 11.5958 17.9459 11.4695 17.1663C11.3428 16.384 11.4654 15.5817 11.82 14.8731M23.852 15.2084L24.5228 14.8732C24.2551 14.3382 23.8641 13.8741 23.382 13.5197C22.9 13.1654 22.3406 12.9306 21.7501 12.8349C21.1596 12.7391 20.5547 12.785 19.9854 12.9688C19.4161 13.1526 18.8986 13.4691 18.4755 13.8921L18.1709 14.1968L17.8663 13.8922C17.8663 13.8922 17.8662 13.8922 17.8663 13.8922C17.4433 13.4692 16.9259 13.1526 16.3566 12.9688C15.7874 12.785 15.1826 12.7391 14.5921 12.8348C14.0016 12.9306 13.4423 13.1654 12.9603 13.5198C12.4785 13.8741 12.0875 14.3381 11.82 14.8731'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.9258 2.72401C13.9945 2.14739 11.938 2.13882 10.002 2.69932C8.06593 3.25982 6.33212 4.36569 5.00757 5.88489C3.68302 7.4041 2.82371 9.27242 2.53225 11.2668C2.24078 13.2611 2.52949 15.2972 3.36388 17.132C4.19828 18.9667 5.5431 20.5225 7.23777 21.6136C8.93244 22.7047 10.9053 23.285 12.9209 23.2852C13.3351 23.2852 13.6708 23.621 13.6708 24.0352C13.6708 24.4494 13.3349 24.7852 12.9207 24.7852C10.6173 24.7849 8.36252 24.1217 6.42576 22.8748C4.48899 21.6278 2.95205 19.8497 1.99845 17.7529C1.04486 15.6561 0.714913 13.3291 1.04801 11.0499C1.38111 8.7706 2.36318 6.63537 3.87695 4.89914C5.39072 3.16291 7.37222 1.89905 9.58484 1.25849C11.7975 0.617919 14.1477 0.627713 16.3549 1.2867C18.5621 1.94568 20.533 3.22601 22.0322 4.9748C23.5315 6.72359 24.4957 8.86693 24.8098 11.1489C24.8663 11.5592 24.5794 11.9377 24.1691 11.9942C23.7587 12.0506 23.3803 11.7638 23.3238 11.3534C23.049 9.35671 22.2053 7.48128 20.8934 5.95109C19.5816 4.42091 17.8571 3.30062 15.9258 2.72401Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6321 1.24447C10.9745 1.47765 11.0629 1.94421 10.8298 2.28655C9.42814 4.34428 8.4209 8.22681 8.4209 12.7853C8.4209 17.344 9.42818 21.2274 10.8297 23.284C11.0629 23.6263 10.9745 24.0928 10.6322 24.3261C10.29 24.5594 9.82338 24.471 9.59012 24.1287C7.94962 21.7213 6.9209 17.4927 6.9209 12.7853C6.9209 8.07785 7.94965 3.85038 9.59003 1.44211C9.82322 1.09977 10.2898 1.01128 10.6321 1.24447Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.946289 12.0352C0.946289 11.6209 1.28208 11.2852 1.69629 11.2852H11.2193C11.6335 11.2852 11.9693 11.6209 11.9693 12.0352C11.9693 12.4494 11.6335 12.7852 11.2193 12.7852H1.69629C1.28208 12.7852 0.946289 12.4494 0.946289 12.0352Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.16992 6.03516C3.16992 5.62094 3.50571 5.28516 3.91992 5.28516H21.9209C22.3351 5.28516 22.6709 5.62094 22.6709 6.03516C22.6709 6.44937 22.3351 6.78516 21.9209 6.78516H3.91992C3.50571 6.78516 3.16992 6.44937 3.16992 6.03516Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.21875 18.0352C2.21875 17.6209 2.55454 17.2852 2.96875 17.2852H9.17075C9.58496 17.2852 9.92075 17.6209 9.92075 18.0352C9.92075 18.4494 9.58496 18.7852 9.17075 18.7852H2.96875C2.55454 18.7852 2.21875 18.4494 2.21875 18.0352Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.2095 1.24457C15.5518 1.0113 16.0184 1.09968 16.2517 1.44196C17.7087 3.57996 18.6775 7.14403 18.881 11.1856C18.9018 11.5993 18.5833 11.9516 18.1696 11.9724C17.7559 11.9932 17.4037 11.6747 17.3829 11.261C17.1863 7.35663 16.2511 4.1047 15.0121 2.2867C14.7789 1.94441 14.8673 1.47783 15.2095 1.24457Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_7782_2182'>
        <rect width='24' height='24' fill='white' transform='translate(0.920898 0.785156)' />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
