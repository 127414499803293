import { FC, memo } from 'react';
import { useDialog } from '../../../contexts/dialog';
import { SizesEnum } from '../../../contexts/dialog/types';
import Frame from '../Frame';
import MediumFrame from '../MediumFrame';
import SmallFrame from '../SmallFrame';

const FrameControl: FC = () => {
  const {
    dialogState: { size },
  } = useDialog();

  switch (size) {
    case SizesEnum.LG:
      return <Frame />;

    case SizesEnum.MD:
      return <MediumFrame />;

    default:
      return <SmallFrame />;
  }
};

export default memo(FrameControl);
