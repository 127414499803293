import { FC } from 'react';
import styled from 'styled-components';
import Text from '../../../../../atoms/Text';

interface TProps {
  label: string;
  editable?: boolean;
  field: string;
}

const S = {
  Main: styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  `,
  Box: styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 16px 0px 16px 16px;
    padding: 0 16px;
    background-color: ${({ theme }) => theme.primaryColor};
    height: 39px;
  `,
  EditButton: styled.button`
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    text-decoration: underline;
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  `,
};

export const Response: FC<TProps> = ({ editable, label }) => {
  return (
    <S.Main>
      <S.Box>
        {editable && (
          <S.EditButton id='edit' type='button'>
            <svg
              width='18'
              height='19'
              viewBox='0 0 18 19'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.5 7.00325L11.5605 5.06375L12.375 4.24925L14.3145 6.18875L13.5 7.00325ZM6.3802 14.123L3.9562 14.6075L4.4407 12.1835L10.5 6.12425L12.4395 8.06375L6.3802 14.123ZM15.375 5.12825L13.4355 3.18875C12.8512 2.60375 11.8987 2.60375 11.3145 3.18875L3.2197 11.2835C3.1147 11.3885 3.04345 11.5212 3.01495 11.6667L2.26495 15.4167C2.21545 15.6628 2.2927 15.917 2.4697 16.094C2.61145 16.2365 2.8027 16.3137 2.99995 16.3137C3.0487 16.3137 3.0982 16.3092 3.14695 16.2995L6.89695 15.5495C7.04245 15.5203 7.1752 15.449 7.2802 15.344L15.375 7.24925C15.96 6.66425 15.96 5.71325 15.375 5.12825Z'
                fill='white'
              />
            </svg>
          </S.EditButton>
        )}

        <Text color='white'>{label}</Text>
      </S.Box>
    </S.Main>
  );
};
