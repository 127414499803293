import styled from 'styled-components';

const S = {
  Main: styled.div`
    position: relative;
  `,
  Input: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 25px;
    height: 44px;
    justify-content: flex-start;

    input {
      border: 0;
      margin: 0;
      padding: 0 16px;
      width: calc(100% - 24px);
      background: transparent;
      outline: none;
      font-family: 'Inter, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"';
      font-size: 12px;
      color: #777e90;
    }
  `,
  Button: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 0;
    margin: 0;
    padding: 8px 16px;
    outline: none;
    transition: background ease 0.3s;
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  `,
};

export default S;
