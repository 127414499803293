import { FC } from 'react';
import {
  KeyValue,
  QuestionComponentEnum,
  QuestionOptionsTypeEnum,
  WMModelDTO,
} from '../../../../../../contexts/talk/types';
import OfferCardList from '../../../../../atoms/OfferCardList';
import StoreCardList from '../../../../../atoms/StoreCardList';
import EvaluationMessage from './EvaluationMessage/EvaluationMessage';

import Options from './Options';
import OptionsWithSearch from './OptionsWithSearch';

interface TProps {
  type?: QuestionOptionsTypeEnum;
  data:
    | Array<KeyValue>
    | WMModelDTO
    | Array<{
        id: string;
        name: string;
        slugged: string;
      }>;
  component: QuestionComponentEnum;
}

const Interceptor: FC<TProps> = ({ type, data, component }) => {
  switch (component) {
    case QuestionComponentEnum.PROPOSALS:
      return <OfferCardList />;

    case QuestionComponentEnum.RATING:
      return <EvaluationMessage />;

    case QuestionComponentEnum.SELECT_BUTTON:
      return <Options items={data as Array<KeyValue>} />;

    case QuestionComponentEnum.SELECT_BUTTON_WITH_SEARCH:
      if (type === 14) {
        return (
          <StoreCardList
            items={
              data as Array<{
                id: string;
                name: string;
                slugged: string;
              }>
            }
          />
        );
      }

      return (
        <OptionsWithSearch
          items={
            data as Array<{
              id: string;
              name: string;
              slugged: string;
            }>
          }
          type={type}
        />
      );

    default:
      return <div></div>;
  }
};

export default Interceptor;
