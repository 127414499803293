import { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from '../../../theme';
import FloatingActionButton from '../../atoms/FloatingActionButton/FloatingActionButton';
import DialogContainer from '../../../contexts/dialog/DialogContainer';
import TalkContainer from '../../../contexts/talk/TalkContainer';

import { MainProps } from './types';

const Main: FC<MainProps> = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <TalkContainer>
        <DialogContainer>
          <div>
            <FloatingActionButton />
          </div>
        </DialogContainer>
      </TalkContainer>
    </ThemeProvider>
  );
};

export default Main;
