// eslint-disable-next-line no-useless-escape
export const CPF_VALIDATE = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;

export const CPF_MASK = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

// eslint-disable-next-line no-useless-escape
export const PHONE_VALIDATE = /\(\d{2,}\) \d{4,}\-\d{4}/;

export const PHONE_MASK = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const CEP_VALIDATE = /[0-9]{5}-[\d]{3}/;

export const KILOMETERS_VALIDATE = /^[0-9.]+$/;

export const CEP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

// eslint-disable-next-line
export const EMAIL_VALIDATE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const FULLNAME_VALIDATE = /^[a-zA-Z\u00C0-\u017F´]+\s+[a-zA-Z\u00C0-\u017F´]/;
