type TProps = {
  color: string;
};

const Car = ({ color = '#CC0000' }: TProps) => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_7430_4835)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5809 15.159C17.0028 14.7371 17.5751 14.5 18.1719 14.5C18.7686 14.5 19.3409 14.7371 19.7629 15.159C20.1848 15.581 20.4219 16.1533 20.4219 16.75C20.4219 17.3467 20.1848 17.919 19.7629 18.341C19.3409 18.7629 18.7686 19 18.1719 19C17.5751 19 17.0028 18.7629 16.5809 18.341C16.1589 17.919 15.9219 17.3467 15.9219 16.75C15.9219 16.1533 16.1589 15.581 16.5809 15.159ZM18.1719 16C17.973 16 17.7822 16.079 17.6415 16.2197C17.5009 16.3603 17.4219 16.5511 17.4219 16.75C17.4219 16.9489 17.5009 17.1397 17.6415 17.2803C17.7822 17.421 17.973 17.5 18.1719 17.5C18.3708 17.5 18.5616 17.421 18.7022 17.2803C18.8429 17.1397 18.9219 16.9489 18.9219 16.75C18.9219 16.5511 18.8429 16.3603 18.7022 16.2197C18.5616 16.079 18.3708 16 18.1719 16Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.58088 15.159C5.00284 14.7371 5.57514 14.5 6.17188 14.5C6.76861 14.5 7.34091 14.7371 7.76287 15.159C8.18482 15.581 8.42188 16.1533 8.42188 16.75C8.42188 17.3467 8.18482 17.919 7.76287 18.341C7.34091 18.7629 6.76861 19 6.17188 19C5.57514 19 5.00284 18.7629 4.58088 18.341C4.15893 17.919 3.92188 17.3467 3.92188 16.75C3.92188 16.1533 4.15893 15.581 4.58088 15.159ZM6.17188 16C5.97296 16 5.7822 16.079 5.64154 16.2197C5.50089 16.3603 5.42188 16.5511 5.42188 16.75C5.42188 16.9489 5.50089 17.1397 5.64154 17.2803C5.7822 17.421 5.97296 17.5 6.17188 17.5C6.37079 17.5 6.56155 17.421 6.70221 17.2803C6.84286 17.1397 6.92188 16.9489 6.92188 16.75C6.92188 16.5511 6.84286 16.3603 6.70221 16.2197C6.56155 16.079 6.37079 16 6.17188 16Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.92188 16.75C6.92188 16.3358 7.25766 16 7.67188 16H16.6719C17.0861 16 17.4219 16.3358 17.4219 16.75C17.4219 17.1642 17.0861 17.5 16.6719 17.5H7.67188C7.25766 17.5 6.92188 17.1642 6.92188 16.75Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.01646 5.1354C4.1488 4.89751 4.39965 4.75 4.67188 4.75H12.1279C12.7247 4.75 13.3129 4.89245 13.8436 5.16552C14.3743 5.43859 14.8322 5.83438 15.1791 6.32L17.8078 10H20.8809C21.7686 10.0001 22.6275 10.315 23.3048 10.8888C23.982 11.4627 24.4338 12.2581 24.5797 13.1337L24.9117 15.1268C24.9185 15.1675 24.9219 15.2087 24.9219 15.25C24.9219 15.8467 24.6848 16.419 24.2629 16.841C23.8409 17.2629 23.2686 17.5 22.6719 17.5H19.6719C19.2577 17.5 18.9219 17.1642 18.9219 16.75C18.9219 16.3358 19.2577 16 19.6719 16H22.6719C22.8708 16 23.0616 15.921 23.2022 15.7803C23.3307 15.6519 23.4077 15.4816 23.4201 15.3014L23.1001 13.3802C23.0125 12.8549 22.7415 12.3776 22.3351 12.0333C21.9288 11.689 21.4135 11.5001 20.8809 11.5C20.8809 11.5 20.8809 11.5 20.8809 11.5H17.4219C17.1797 11.5 16.9524 11.383 16.8116 11.1859L13.9586 7.192C13.7505 6.90063 13.4757 6.66315 13.1573 6.49931C12.8389 6.33547 12.486 6.25 12.1279 6.25H5.11289L2.82729 10.3586C2.8178 10.3757 2.80766 10.3923 2.79687 10.4086C2.55151 10.7785 2.42103 11.2127 2.42187 11.6566L2.42188 11.658L2.42188 15.25C2.42188 15.4489 2.50089 15.6397 2.64154 15.7803C2.7822 15.921 2.97296 16 3.17188 16H4.67188C5.08609 16 5.42188 16.3358 5.42188 16.75C5.42188 17.1642 5.08609 17.5 4.67188 17.5H3.17188C2.57514 17.5 2.00284 17.2629 1.58088 16.841C1.15893 16.419 0.921875 15.8467 0.921875 15.25V11.6587M4.01646 5.1354L1.5299 9.60524Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.06445 10.75C1.06445 10.3358 1.40024 10 1.81445 10H17.4225C17.8367 10 18.1725 10.3358 18.1725 10.75C18.1725 11.1642 17.8367 11.5 17.4225 11.5H1.81445C1.40024 11.5 1.06445 11.1642 1.06445 10.75Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.67188 4.75C8.08609 4.75 8.42188 5.08579 8.42188 5.5V10.75C8.42188 11.1642 8.08609 11.5 7.67188 11.5C7.25766 11.5 6.92188 11.1642 6.92188 10.75V5.5C6.92188 5.08579 7.25766 4.75 7.67188 4.75Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.67188 5.5C1.67188 5.08579 2.00766 4.75 2.42188 4.75H4.67188C5.08609 4.75 5.42188 5.08579 5.42188 5.5C5.42188 5.91421 5.08609 6.25 4.67188 6.25H2.42188C2.00766 6.25 1.67188 5.91421 1.67188 5.5Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_7430_4835'>
        <rect width='24' height='24' fill='white' transform='translate(0.921875 0.25)' />
      </clipPath>
    </defs>
  </svg>
);

export default Car;
