import { FC, memo } from 'react';
import styled from 'styled-components';

import Text from '../../atoms/Text';

const S = {
  Container: styled.div`
    height: 135px;
    width: 295px;
    border-radius: 32px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 0px 8px 24px rgba(46, 45, 55, 0.24);
  `,
  Header: styled.div`
    height: 54px;
    background: #cc0000;
    border-radius: 32px 32px 0 0;
    padding: 14px 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  `,
  Body: styled.div`
    border-radius: 32px;
    background: #fff;
    margin-top: -25px;
    padding: 24px;
  `,
  Icon: styled.button`
    margin: 0;
    padding: 0;
    border: 0;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
  `,
};

const Frame: FC = () => {
  return (
    <S.Container>
      <S.Header>
        <Text fontSize='14px' color='#fff' lineHeight='21px'>
          Ainda estou aqui 👋
        </Text>
        <S.Icon>
          <svg
            width='17'
            height='17'
            viewBox='0 0 17 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.25008 8.46686L13.7041 4.01284C14.0047 3.71221 14.0047 3.22629 13.7041 2.92566C13.4035 2.62504 12.9176 2.62504 12.6169 2.92566L8.16291 7.37969L3.70888 2.92566C3.40826 2.62504 2.92233 2.62504 2.62171 2.92566C2.32108 3.22629 2.32108 3.71221 2.62171 4.01284L7.07573 8.46686L2.62171 12.9209C2.32108 13.2215 2.32108 13.7074 2.62171 14.0081C2.77164 14.158 2.96847 14.2333 3.1653 14.2333C3.36212 14.2333 3.55895 14.158 3.70888 14.0081L8.16291 9.55403L12.6169 14.0081C12.7669 14.158 12.9637 14.2333 13.1605 14.2333C13.3573 14.2333 13.5542 14.158 13.7041 14.0081C14.0047 13.7074 14.0047 13.2215 13.7041 12.9209L9.25008 8.46686Z'
              fill='white'
            />
          </svg>
        </S.Icon>
      </S.Header>
      <S.Body>
        <Text fontSize='14px' color='#353945' lineHeight='21px'>
          Você pode continuar de onde parou, basta clicar aqui. 😃
        </Text>
      </S.Body>
    </S.Container>
  );
};

export default memo(Frame);
