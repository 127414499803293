const API_URL = 'http://chat.laborit.com.br/api/v1';

const config = {
  api: {
    baseURL: process.env.API_BASE_URL || API_URL,
    headers: {
      contentType: process.env.API_HEADERS_CONTENT_TYPE || 'application/json',
    },
  },
  localStorage: {
    xKey: 'CHATBOT-XKEY',
  },
};

export default config;
