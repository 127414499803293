import { FC } from 'react';
import styled from 'styled-components';
import Text from '../../../../../../../atoms/Text';

interface IProps {
  value: string;
}
const S = {
  Box: styled.div`
    display: flex;
    margin-bottom: 16px;
  `,
  Message: styled.div`
    max-width: 60%;
    background: #f0f0f0;
    padding: 12px 16px;
    border-radius: 0 16px 16px 16px;
    display: inline-flex;

    > div {
      line-height: 150%;
      margin: 0 !important;
    }
  `,
};

const TextQuestion: FC<IProps> = ({ value }) => {
  return (
    <S.Box>
      <S.Message>
        <Text color='#444' fontSize='12px'>
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </Text>
      </S.Message>
    </S.Box>
  );
};

export default TextQuestion;
