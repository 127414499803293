import { FC, useState } from 'react';

import styled from 'styled-components';
import client from '../../../api';
import config from '../../../api/config';
import { useDialog } from '../../../contexts/dialog';
import { DialogTypeEnum } from '../../../contexts/dialog/types';
import { useTalk } from '../../../contexts/talk';
import mocks, { OfferType } from '../../../helpers/mocks';
import Button from '../Button';
import Text from '../Text';

interface OfferProps {
  onSelect?: (value: string | number | boolean, answer?: string) => void;
  imagePath?: string;
  title?: string;
  subtitle?: string;
  id: number | string;
  offer?: string;
}

interface IBrandList {
  onSelect?: () => void;
  items: Array<{
    id: string;
    name: string;
    slugged: string;
  }>;
}

const S = {
  Container: styled.div`
    padding: 16px;
    margin: 0;
    margin-bottom: 18px;
    background: #f0f0f0;
    border-radius: 16px;
    position: relative;

    img,
    .img {
      max-width: 90px;
      max-height: 52px;
      margin-right: 10px;
    }

    .mt6 {
      margin-top: 12px;
    }

    .flex {
      display: flex;
    }

    .items-start {
      align-items: flex-start;
    }

    .items-end {
      align-items: flex-end;
    }

    .items-center {
      align-items: center;
    }

    .content-start {
      justify-content: flex-start;
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .head {
      height: 52px;
    }
  `,
  Tag: styled.div`
    height: 20px;
    border-radius: 12px;
    background-color: #fff;
    padding: 0 10px;
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 16px;
  `,
};

const List = styled.div``;

const OfferCard: FC<OfferProps> = ({ onSelect, title, id }) => {
  return (
    <S.Container>
      <div className='flex items-center head'>
        <div className='img'>
          <svg
            width='51'
            height='50'
            viewBox='0 0 51 50'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='25.9219' cy='25' r='25' fill='white' />

            <g clipPath='url(#clip0_8163_8995)'>
              <path
                d='M16.3223 23.344V34.6C16.3223 34.9183 16.4487 35.2235 16.6737 35.4485C16.8988 35.6736 17.204 35.8 17.5223 35.8H34.3223C34.6405 35.8 34.9458 35.6736 35.1708 35.4485C35.3958 35.2235 35.5223 34.9183 35.5223 34.6V23.344'
                stroke='#CC0000'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M34.689 15.0808C34.5443 14.8132 34.3297 14.59 34.0681 14.4349C33.8064 14.2797 33.5076 14.1985 33.2034 14.2H18.6402C18.3361 14.1985 18.0372 14.2797 17.7756 14.4349C17.5139 14.59 17.2993 14.8132 17.1546 15.0808L15.1218 18.9784V21.1C15.1218 21.8161 15.4063 22.5028 15.9126 23.0092C16.419 23.5155 17.1057 23.8 17.8218 23.8C18.5379 23.8 19.2247 23.5155 19.731 23.0092C20.2374 22.5028 20.5218 21.8161 20.5218 21.1C20.5218 21.8161 20.8063 22.5028 21.3126 23.0092C21.819 23.5155 22.5057 23.8 23.2218 23.8C23.9379 23.8 24.6247 23.5155 25.131 23.0092C25.6374 22.5028 25.9218 21.8161 25.9218 21.1C25.9218 21.8161 26.2063 22.5028 26.7126 23.0092C27.219 23.5155 27.9057 23.8 28.6218 23.8C29.3379 23.8 30.0247 23.5155 30.531 23.0092C31.0374 22.5028 31.3218 21.8161 31.3218 21.1C31.3218 21.8161 31.6063 22.5028 32.1126 23.0092C32.619 23.5155 33.3057 23.8 34.0218 23.8C34.7379 23.8 35.4247 23.5155 35.931 23.0092C36.4374 22.5028 36.7218 21.8161 36.7218 21.1V18.9784L34.689 15.0808Z'
                stroke='#CC0000'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M27.1218 23.344V35.8'
                stroke='#CC0000'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M16.3223 29.8H27.1223'
                stroke='#CC0000'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </g>
            <defs>
              <clipPath id='clip0_8163_8995'>
                <rect width='24' height='24' fill='white' transform='translate(13.9219 13)' />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div>
          <Text fontSize='14px' color='#222222' lineHeight='125%' bold>
            {String(id) === '5efb4d8b853903000ac4ec1b'
              ? 'Honda Sinal Vergueiro'
              : 'Concessionária Amazonas'}
          </Text>
          <Text fontSize='10px' color='#767676' lineHeight='150%'>
            {title}
          </Text>
        </div>
      </div>

      <div className='head mt6'>
        <Text fontSize='12px' color='#767676' lineHeight='150%'>
          Rua Vergueiro, 4911 - Vila Mariana, São Paulo - SP, 04101-300
        </Text>
      </div>

      <div className='flex'>
        <Button
          onClick={() => onSelect?.('5efb4d8b853903000ac4ec1b', 'Cobertura completa')}
          margin='0 0 0 6px'
          full
        >
          Escolher
        </Button>
      </div>
    </S.Container>
  );
};

const StoreCardList: FC<IBrandList> = ({ items }) => {
  const offers: OfferType[] = mocks.offers;

  const { handleDialog } = useDialog();

  const { appendRound } = useTalk();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  const handleAction = async (value: string | number | boolean, answer?: string) => {
    setLoading(true);

    answer &&
      appendRound({
        answer: {
          id: Math.random(),
          editable: false,
          value: 'Concessionária Amazonas',
        },
      });

    const xKey = localStorage.getItem(config.localStorage.xKey);

    try {
      const { data } = await client.patch(`/chats/${xKey}`, {
        data: value,
      });

      appendRound({ question: data });

      handleDialog({
        type: DialogTypeEnum.TALK,
        componentProps: {
          id: null,
        },
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <List>
      {(items || offers).map(({ id }) => (
        <OfferCard key={id} id={id} onSelect={handleAction} />
      ))}
    </List>
  );
};

export default StoreCardList;
